import React from "react"
import { Layout } from '../layout'
export default function About() {
  return <Layout sidebar={false}>
    <div className="about">
      <div className="about__mission">
        <blockquote>"... we never say no to friends!"</blockquote>
      </div>
      <div className="about__main">
        <img src="/layout/erics_about.jpeg" alt="Eric's Bistro - George" />
        <div className="about__erics">
          <p>Chef George Echeverria, at an earlier stage of his career worked along some of the highest rated Chefs and Restaurants around. He absorbed all the positives, and added his own special touches to his restaurants. Today he is highly regarded as one of the Top Chefs/Restaurateur on Long Island.</p>
          <p>Eric was his dear Uncle…his father figure…his best friend. He chose to honor him by naming his Restaurant after him. The front wall of the restaurant has been dedicated, through pictures, to his memory. Ask George about him, and he will let you know in detail, just how special of a man he was!</p>
          <p>Any night you come, you will find George and any of his family members... his wife Fanny, his sister Betty... his sister Lissette... his daughter Ashley... his daughter Stephanie... his son Nicholas or his young daughters, Brooke &amp; Gabby... greeting you with a huge smile. They, along with his entire staff will assure a memorable dining experience.</p>
          <h3>George Echeverria<br />Owner / Chef</h3>
        </div>
      </div>
      <div className="about__images">
        {[1, 2, 3].map(i => <img src={`/layout/about_image_${i}.jpeg`} alt="About Eric's Bistro" key={`about-image-${i}`} />)}
      </div>
    </div>
  </Layout>
}
